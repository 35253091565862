<template>
	<div class="fileDownload">
		<van-dialog v-model="showPopup" title="下载方式" show-cancel-button @confirm="handleConfirm" :confirmButtonText="selectIndex==1?'下载':'关闭'" confirmButtonColor="#2594ef">
			<van-radio-group v-model="selectIndex">
				<van-cell-group>
					<van-cell title="直接下载" clickable @click="handleClick(1)">
						<template #right-icon>
							<van-radio :name="1"></van-radio>
						</template>
					</van-cell>
					<div v-if="!more" class="more" @click="handleMore"><van-icon name="arrow-down" color="2c3e50"/>更多方式</div>
					<template v-else>
						<van-cell title="文件下载链接" clickable @click="handleClick(2)">
							<template #right-icon>
								<van-radio :name="2"></van-radio>
							</template>
						</van-cell>
						<!-- <van-cell title="发送到邮箱" clickable @click="handleClick(3)">
							<template #right-icon>
								<van-radio :name="3"></van-radio>
							</template>
						</van-cell>
						<van-cell title="发送到短信" clickable @click="handleClick(4)">
							<template #right-icon>
								<van-radio :name="4"></van-radio>
							</template>
						</van-cell> -->
					</template>
				</van-cell-group>
			</van-radio-group>
		</van-dialog>

		<van-dialog v-model="showOptions" :title="title" :show-cancel-button="selectIndex!=2" :before-close="beforeClose" confirmButtonColor="#2594ef">
			<div v-if="selectIndex == 2" class="pv_20">
				<div @click="handleCopy" class="copy"><van-icon name="orders-o" />点击复制下载链接</div>
				<div class="mt_20 flex_center"><img :src="imgUrl" class="image_box" /><span>扫码下载</span></div>
				

				<!-- <van-radio-group v-model="selectOptions">
					<van-cell-group>
						<van-cell title="点击复制下载链接" clickable>
							<template #right-icon>
								<van-radio name="copy"></van-radio>
							</template>
						</van-cell>
						<van-cell title="扫码下载" clickable>
							<template #right-icon>
								<van-radio name="code"></van-radio>
							</template>
						</van-cell>
					</van-cell-group>
				</van-radio-group> -->
			</div>
			<template v-if="selectIndex > 2">
				<van-field v-model="selectOptions" :error="error" :label="selectIndex === 3 ? '邮箱' : '手机号'" required :placeholder="selectIndex === 3 ? '请输入邮箱' : '请输入手机号'" label-width="1rem"></van-field>
			</template>
		</van-dialog>
	</div>
</template>

<script>
import { Dialog, CellGroup, Cell, Radio, RadioGroup, field, Toast, Icon } from 'vant';
import { http_insFileDownload } from '@/request/common.js';
import ClipboardJS from 'clipboard';
import axios from 'axios';
import utils from '@/assets/js/base.js';

export default {
	components: {
		[Dialog.Component.name]: Dialog.Component,
		[CellGroup.name]: CellGroup,
		[Cell.name]: Cell,
		[Radio.name]: Radio,
		[RadioGroup.name]: RadioGroup,
		[Icon.name]: Icon,
		[field.name]: field,
		[Toast.name]: Toast,
	},
	data() {
		return {
			selectIndex: 1, //下载方式选中
			showOptions: false,
			title: '', //标题
			selectOptions: '',
			error: false, //必填错误显示
			imgUrl: '', //复制
			more: false, //更多
		};
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		url: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '文件',
		},
	},
	computed: {
		showPopup: {
			get() {
				if (this.show) {
					this.selectIndex = 1;
					this.more = false;
					this.error=false
				}
				return this.show;
			},
			set(val) {
				this.$emit('update:show', val);
			},
		},
		fileUrl() {
			let url = this.url;
			if (this.url.indexOf('http') == -1 && this.url.indexOf('https') == -1){
				let host = 'https://h5.yaobw18.com/';
				if (window.location.host.indexOf('127.0.0.1') !== -1 || window.location.host.indexOf('localhost') !== -1|| window.location.host.indexOf('testh5.yaobw18.com')!== -1) {
					host = 'https://testh5.yaobw18.com';
				}
				url = host + url;
			}
			return url;
		},
	},

	methods: {
		// 选择下载方式
		handleClick(val) {
			const label = ['文件下载链接', '发送到邮箱', '发送到短信'];
			this.selectOptions = '';
			if (val > 1) {
				this.selectIndex = val;
				this.showOptions = true;
				this.title = label[val - 2];
				this.showPopup = false;
				if (val === 2) {
					axios.post(`/insurance/api/insFileComponent/insFileDownload/3`,{url: this.fileUrl,},{ responseType: 'blob' },)
					.then(res => {
						this.imgUrl = window.URL.createObjectURL(res);
					});
				}
			}
		},

		//点击复制
		async handleCopy(){
			// 复制文件地址-链接二维码
			if (this.selectIndex === 2) {
				this.copyEventInit().then(res => {
					if (res) {
						Toast.success('复制成功');
					}
				})
			} 	
		},

		//关闭弹框
		handleConfirm() {
			if (this.selectIndex === 1 && this.url) {
				const that=this
				if(this.$base.isMobile()){
                  window.location.href=this.fileUrl
				}else{
					axios
					.post(
						`/insurance/api/insFileComponent/insFileDownload/1`,
						{
							url: this.fileUrl,
						},
						{ responseType: 'blob' },
					)
					.then(res => {
							const reader = new FileReader();
							reader.readAsText(res, 'utf-8');
							reader.onload = function () {
							if(reader.result.indexOf('success')!==-1||reader.result.indexOf('message')!==-1){
								const _res = JSON.parse(reader.result);
								Toast.fail(_res.message);
								return
							}
							utils.saveAsText(that.name + '.' + res.type.split('/')[1], res);
							};
					})
				}
			}
			
		},

		//关闭弹框之前
		async beforeClose(action, done) {
			if (action == 'cancel') {
				done();
			} else {
				if(this.selectIndex==2){
					done();
				}
				if (this.selectIndex > 2) {
					if (!this.selectOptions) {
						this.error = true;
						done(false);
						return;
					}
					this.error = false;
					let params = this.selectIndex === 3 ? { email: this.selectOptions } : { phone: this.selectOptions };
					params.url = this.fileUrl;
					const res = await http_insFileDownload(this.selectIndex + 1, params);
					if (res.success) {
						Toast.success('操作成功,请注意查收');
						done();
					} else {
						Toast.fail(res.message);
						done(false);
					}
				}
			}
		},

		// 初始化复制事件
		copyEventInit() {
			return new Promise(resolve => {
				let clipboard = new ClipboardJS('.fileDownload', {
					text: () => {
						return this.fileUrl;
					},
				});
				clipboard.on('success', e => {
					e.clearSelection();
					resolve(true);
				});

				clipboard.on('error', e => {
					resolve(false);
				});
			});
		},

		//加载更多
		handleMore() {
			this.more = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.image_box {
	width: 100%;
}
.more {
	display: flex;
	justify-content: center;
	font-size: 0.2rem;
	align-items: center;
	color: $color_main;
	padding: 0.2rem;
}
.pv_20{
	padding: 20px;
}
.mt_20{
	margin-top: 20px;
}
.copy{
	color: $color_main;
	text-align: center;
}
.flex_center{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

</style>
